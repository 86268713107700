// libraies
import React, { useEffect, useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';

// components

// services and hooks
import { FetchService } from '../../services';
import { geti18nText, geti18nLng, geti18nCC } from '../../assets/i18n/lang';

// config
import { SETTINGS } from '../../config/settings';
import FORM_CONFIG from '../../config/formConfig.json';

// styles and resources

// consts and independent functions
import arrays from '../../tools/arrays';

const $SERVER = SETTINGS.server;

import {getConstData} from '../../config/constants';
import {parseQueryParams} from "../../helpers/common";
/**
 * @category Views
 * @class
 * Main view
 *
 * @param  {Object} props
 * @return {React.JSX.Element}
 */
const RegisterForm = (props) => {
  const {onRegister} = props;
  const [formData, setFormData] = useState({
    country: null,
    language: null,
    primaryUse: '002',
    isCompany: false
  });
  const [searchData, setSearchData] = useState({isSearching: false, search: false, data: []});
  const [serchValue, setSearchValue] = useState('');
  const [formConfig, setFormConfig] = useState([]);

  useEffect(() => {
    setFormData(prevState => ({
      ...prevState,
      country: parseQueryParams().cc || prevState.cc,
      language: parseQueryParams().lang || prevState.lang
    }))
  }, []);

  const isVisibleField = (key) => formConfig.some((f) => f.id === key && !f.visible) ? {display: 'none'} : {};

  const setFormValue = (key, value) => {
    setFormData((prevState) => ({...prevState, [key]: value}))
  };

  const selectProduct = (item) => {
    if (!formData.primaryUse) {
      return null;
    }

    if (!item) {
      return null;
    }

    const obj = {
      ...formData,
      country: formData.country || geti18nCC(),
      language: formData.language || geti18nLng(),
      productNumberName: item.product_number_name,
      productName: item[`${geti18nLng() !== 'en' ? `${geti18nLng()}_` : ''}product_name_name`]
    }

    onRegister(obj);
  };

  const getSearchData = async () => {
    setSearchData((prevState) => ({...prevState, isSearching: true}));

    const data = [];
    const lng = `${geti18nLng() !== 'en' ? `${geti18nLng()}_` : ''}`;
    const body = {
      "index": $SERVER.index.products,
      "paths": [],
      "maxResults": 100,
      "filters": {},
      "isFullTextSearch": true,
      "aggregations": []
    };
    const body_name = { ...body, "filters": {
      [`${lng}product_name_name.search`]: [`*${serchValue.replace(new RegExp(' ', 'g'), '\\ ')}*`],
    }};

    const body_number = { ...body, "filters": {
      [`product_number_name.search`]: [`*${serchValue.replace(new RegExp(' ', 'g'), '\\ ')}*`],
    }};

    const promises = [
      FetchService.post(body_name, 'search', 'api'),
      FetchService.post(body_number, 'search', 'api')
    ];

    const resp = await Promise.all(promises);
    const [resp_name, resp_number] = resp;

    if (resp_name && resp_name.status === 200) {
      data.push(...resp_name.results.results);
    }

    if (resp_number && resp_number.status === 200) {
      data.push(...resp_number.results.results);
    }

    setSearchData({ isSearching: false, search: true, data });
  };

  useEffect(() => {
    setFormConfig(FORM_CONFIG[geti18nLng()] || []);
  }, []);

  return (
      <div className="form-container">
        <div className="title-container"><h1>{geti18nText('title_register')}</h1></div>
        <div className="subtitle-container"><h2>{geti18nText('subtitle_find_product')}</h2></div>
        { SETTINGS.safetyRecallCountries[formData.country?.toUpperCase() || geti18nCC()?.toUpperCase()] &&
        <div className="safety-recall-container">
            <p dangerouslySetInnerHTML={{ __html: geti18nText('safety_recall').replace('%COUNTRY_CODE%', (formData.country?.toUpperCase() || geti18nCC()?.toUpperCase()))}}></p>
        </div>
        }
        <div className="required-container"><span className="required">*</span>{geti18nText('requerido')}</div>
        <div className="block-container">
          <div className="input-container" style={isVisibleField('field_country_region')}>
            <label>{geti18nText('field_country_region')}<span className="required"> * </span></label>
            {<Form.Select value={(formData.country || geti18nCC()).toUpperCase()} onChange={(v) => {
              let lng = (formData.language || geti18nLng())
              let cc = v.target.value
              /*
              formData.language || geti18nLng()
              const dat = getConstData('country_region', geti18nLng());
              const f = dat.find((i) => i.value.indexOf(`${v.target.value}_`) >= 0);
              const [lng, cc] = f.value.split('_');
              */

              window.open(`${window.location.origin}?cc=${cc}&lang=${lng}`, '_self');
            }}>
              {getConstData('country_region', geti18nLng())
                .map((item) => ({value: item.value.slice(item.value.indexOf('_') + 1).trim(), label: item.label.slice(0, item.label.indexOf('-'))}))
                .filter((item, idx, array) => {
                  const a = array.slice(0, idx);
                  const fnd = a.some((i) => i.value === item.value);

                  return !fnd;
                })
                .map((item, index) => (<option key={`${item.value}_${index}_country`} value={item.value}>{item.label}</option>))}
              </Form.Select>}
          </div>
          <div className="input-container pl20" style={isVisibleField('field_product_read')}>
            <label>{geti18nText('field_product_read')}<span className="required"> * </span></label>
            {<Form.Select value={formData.language || geti18nLng()} onChange={(v) => {
              let lng = v.target.value
              let cc = (formData.country || geti18nCC()).toUpperCase()
              /*
              formData.language || geti18nLng()
              const dat = getConstData('country_region', geti18nLng());
              const f = dat.find((i) => i.value.indexOf(`${v.target.value}_`) >= 0);
              const [lng, cc] = f.value.split('_');
              */

              window.open(`${window.location.origin}?cc=${cc}&lang=${lng}`, '_self');
            }}>
              {getConstData('country_region', geti18nLng())
                .map((item) => ({value: item.value.slice(0, item.value.indexOf('_')), label: item.label.slice(item.label.indexOf('-') + 1).trim()}))
                .filter((item, idx, array) => {
                  const a = array.slice(0, idx);
                  const fnd = a.some((i) => i.value === item.value);

                  return !fnd;
                })
                .map((item, idx) => (<option key={`${item.value}_${idx}_language`} value={item.value}>{item.label}</option>))}
              </Form.Select>}
          </div>
        </div>
        <div className="block-container">
          <div className="input-container-long" style={isVisibleField('field_product_used')}>
            <label>{geti18nText('field_product_used')}<span className="required"> * </span></label>
            <Form.Select value={formData.primaryUse} onChange={(e) => {
              setFormValue('primaryUse', e.target.value);
              setFormValue('isCompany', e.target.value !== '002');
            }}>
              {getConstData('primaryUse', geti18nLng()).map((item) => (<option key={`${item.value}_primaryuse`} value={`${item.value}`}>{item.label}</option>))}
            </Form.Select>
          </div>
        </div>
        <div className="block-container">
          <div className="input-container" style={isVisibleField('field_product_name_number')}>
            <label>{geti18nText('field_product_name_number')}<span className="required"> * </span></label>
            <input type="text" value={serchValue} onChange={(obj) => setSearchValue(obj.target.value)} onKeyPress={(key) => key.charCode === 13 && getSearchData()}/>
          </div>
          <div className="button-container pl20">
            {!searchData.isSearching
              ? <input className="secondary-button" name="search-btn" type="button" id="search-role" value={geti18nText('btn_search')} onClick={getSearchData}/>
              : <div className="spin-container">
                <Spinner animation="border" size="lg" />
              </div>}
          </div>
        </div>
        <div className="block-container-result">
          {(!arrays.isArrayWithContent(searchData.data) && searchData.search) && (<div><h5>{geti18nText('text_no_result_found')}</h5></div>)}
          {arrays.isArrayWithContent(searchData.data) && (
            <div className="block-container-result-list">
              <ul>
                {searchData.data.map((item) => (
                  <li key={`${item.id}`} onClick={() => selectProduct(item)}>{item[`${geti18nLng() !== 'en' ? `${geti18nLng()}_` : ''}product_name_name`]}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
)};

export default RegisterForm;
