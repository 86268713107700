// libraries
import React, { useRef } from 'react';
import RecaptchaWrapper from 'react-google-recaptcha/lib/recaptcha-wrapper';
import { getCaptchaLang } from '../../assets/i18n/lang';

// components

// services and hooks
import CaptchaService from '../../services/captchaService';

// config

// styles and resources

// consts and independent functions

/** 
 * @category Components
 * @class
 * Component text input
 *
 * @param  {Object} props
 * @param  {Function} props.setValidated
 */
const Captcha = (props) => {
    const SITEKEY = CaptchaService.getSiteKey();
    const LANG = getCaptchaLang();
    const captchaRef = useRef(null);

    const onChange = () => {
        if (captchaRef.current.getValue()) {
            props.setValidated(true);
        }
    }
    return <RecaptchaWrapper hl={LANG} ref={captchaRef} sitekey={SITEKEY} onChange={onChange}/>;
}

export default Captcha;